<template>
    <div class="">
        <div class="">
            <b-card class="grid-view-item overflow-hidden">
                <div id="container-map" v-if="status_mapa">
                    <l-map :center="center" :zoom="zoom" ref="mymap" style="height: 100%; overflow: hidden;" :options="{attributionControl: false, zoomControl: false, fullscreenControl: true}">
                        <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
                        <l-control-layers :collapsed="true" :position="layersPosition" :sort-layers="true"/>
                        <l-tile-layer :key="tileProvider.key" :name="tileProvider.name" :url="tileProvider.url" :visible="tileProvider.visible" layer-type="base" v-for="tileProvider in tileProviders"/>
                        <l-control-zoom position="topleft" :options="{ zoomInTitle: $t('Labels.ZoomIn'), zoomOutTitle: $t('Labels.ZoomOut') }"/>
                        <l-control position="topright" v-if="showButtons === true">
                          <b-button v-if="fitBounds == false"  @click="fitBounds = true" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.Follow')" v-b-tooltip.right="{ variant: 'primary' }" >
                            <feather-icon icon="EyeIcon" :width="18" :height="18"/>
                          </b-button>
                          <b-button v-else @click="fitBounds = false" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.StopFollow')" v-b-tooltip.right="{ variant: 'primary' }" >
                            <feather-icon icon="EyeOffIcon" :width="18" :height="18" />
                          </b-button>
                          <b-button @click="showFilters = !showFilters" v-if="!showFilters" class="btn-icon btn-map rounded-circle" variant="primary" :title="$t('Labels.Filter')" v-b-tooltip.right="{ variant: 'primary' }" >
                            <iconBase :width="18" :height="18" iconColor="#FFFF" ><iconFilter/></iconBase>
                          </b-button>
                        </l-control>
                        <l-control position="topright">
                          <b-card v-if="showFilters">
                            <b-row align-h="end">
                              <b-col>
                                <b-button
                                  variant="flat-primary"
                                  class="btn-icon float-right btn-map rounded-circle"
                                  @click="showFilters = false"
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="12">
                                <b-form-group
                                  :label="$tc('Labels.Trackers')"
                                  label-for="filter"
                                >
                                  <b-form-checkbox
                                    checked="true"
                                    class="custom-control-primary"
                                    name="check-button"
                                    v-model="checkAll"
                                  >
                                    {{$t('Labels.All')}}
                                  </b-form-checkbox>
                                  <b-form-select name="filter" v-model="filter" :options="trackerOptions" multiple :select-size="10" />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="12">
                                <b-button
                                  variant="primary"
                                  @click="getMapa()"
                                  class="mt-1 float-right"
                                >
                                  {{$t("Labels.Filter")}}
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-card>
                        </l-control>
                        <l-control position="verticalcenterright" class="leaflet-topcenter">
                          <div v-if="this.onAlert" class="container-tags my-1">
                            <h5>{{$t('Labels.TrackerOnAlert')}}</h5>
                            <badgeTracker v-for="item in alertTrackers" :key="item.id"
                              :label="item.name"
                              :status="item.status"
                              @click="goToMarker(item.position)"
                            />
                          </div>
                        </l-control>
                        <v-marker-cluster class="micluster">
                            <l-marker :draggable="item.draggable" :key="item.id" :lat-lng.sync="item.position" :visible="item.visible" v-for="item in markers">
                                <l-icon :icon-size="[40,40]">
                                  <avatarMarker
                                    :label="item.name.substr(0,2)"
                                    :status="item.status"
                                  />
                                </l-icon>
                                <l-popup>
                                    <div class="popup">
                                      <valueItem :value="item.name" :description="$t('Labels.Name')" img="pointtracker"/>
                                      <valueItem :value="item.serie" :description="$t('Labels.Serie')" img="alias"/>
                                      <valueItem :value="item.lat" :description="$t('Labels.Latitude')" img="georeference"/>
                                      <valueItem :value="item.long" :description="$t('Labels.Longitude')" img="georeference"/>
                                      <valueItem :value="item.battery" :description="$t('Labels.Battery')" img="battery" unit="%"/>
                                      <hr>
                                      <alertTracker :status="item.status" />
                                      <valueItem :value="item.updated_at.split(' ')[0]" :description="item.updated_at.split(' ')[1]" img="fecha"/>
                                      <div class="text-center">
                                        <b-button @click="deviceInformation(item.id)" variant="primary" size="sm">
                                          {{$t('Labels.SeeMore')}}
                                        </b-button>
                                      </div>

                                </div>
                                </l-popup>
                            </l-marker>
                        </v-marker-cluster>
                    </l-map>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
import {
  LMap,
  LControlAttribution,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControlLayers,
  LControl,
  LControlZoom
} from 'vue2-leaflet'

import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import request from '@/libs/request/index'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
import { batteryCalculate, empty } from '@/libs/tools/helpers'
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BFormSelect,
  BFormGroup,
  BFormCheckbox
} from 'bootstrap-vue'
import avatarMarker from '@core/spore-components/avatarMarker/avatarMarker.vue'
import badgeTracker from '@core/spore-components/badgeTracker/badgeTracker.vue'
import iconBase from '@core/spore-components/icons/iconBase'
import iconFilter from '@core/spore-components/icons/iconFilter'
import { app } from '@/main'

const tileProviders = [
  {
    key: 'gray',
    name: app.$t('Labels.Gray'),
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    key: 'street',
    name: app.$t('Labels.Street'),
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    key: 'satellite',
    name: app.$t('Labels.Satellite'),
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

const DATA = {
  code: 200,
  data: [],
  msg: 'Ok'
}

export default {
  name: 'mapa',
  components: {
    LMap,
    LControlAttribution,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlLayers,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    valueItem,
    alertTracker,
    BButton,
    BCard,
    avatarMarker,
    LControl,
    LControlZoom,
    badgeTracker,
    iconBase,
    iconFilter,
    BCol,
    BRow,
    BFormSelect,
    BFormGroup,
    BFormCheckbox
  },
  data () {
    return {
      response: [],
      tileProviders,
      layersPosition: 'topright',
      center: [20.662396323989693, -103.37640969334421],
      staticAnchor: [12, 27],
      markers: [],
      status_mapa: false,
      DATA,
      batteryCalculate,
      showButtons: false,
      fitBounds: true,
      alertTrackers: [],
      onAlert: false,
      zoom: 10,
      trackerOptions: [],
      filter: [],
      fullscreen: false,
      checkAll: true,
      showFilters: false
    }
  },
  methods: {
    async getMapa () {
      const params = {
        url: 'tracker/map',
        method: 'GET',
        params: {
          filter: this.filter.join(',')
        }
      }
      await request(params).then(data => {
        data = data.data.data
        const positions = []
        this.markers = []
        this.onAlert = false
        this.alertTrackers = []
        for (let i = 0; i < data.length; i++) {
          if (!empty(data[i].lat) && data[i].lat !== '0' && !empty(data[i].long) && data[i].long !== '0') {
            const lat = data[i].lat
            const lng = data[i].long
            positions.push([lat, lng])
            this.markers.push({
              position: { lat: lat, lng: lng },
              name: data[i].name,
              status: data[i].tipo_alerta,
              updated_at: data[i].updated_at,
              serie: data[i].serie,
              lat: data[i].lat,
              long: data[i].long,
              battery: batteryCalculate(data[i].battery),
              id: data[i].uuid,
              draggable: false,
              visible: true
            })
            if (data[i].tipo_alerta !== 'S') {
              this.onAlert = true
              this.alertTrackers.push({
                id: data[i].uuid,
                name: data[i].name,
                status: data[i].tipo_alerta,
                position: [lat, lng]
              })
            }
          }
        }
        this.status_mapa = true
        const app = this
        setTimeout(function () {
          this.fullscreen = true
          const maps = app.$refs.mymap.mapObject
          maps.fitBounds(positions)
          app.showButtons = true
        }, 1000)
      }).catch(() => {
        this.markers = []
      })
    },

    async getOptionsTrackers () {
      const params = {
        url: 'tracker/list',
        method: 'GET'
      }
      await request(params).then(data => {
        data = data.data.data
        this.trackerOptions = []
        data.forEach(item => {
          this.trackerOptions.push({ value: item.uuid, text: item.name })
        })
      })
    },

    deviceInformation (uuid) {
      this.$router.push(`/tracker/detail/${uuid}`)
    },

    setFitBounds () {
      const positions = []
      this.markers.forEach(item => {
        positions.push([item.position.lat, item.position.lng])
      })
      const maps = this.$refs.mymap.mapObject
      if (maps !== null) {
        maps.fitBounds(positions)
      }
    },
    goToMarker (position) {
      this.fitBounds = false
      const map = this.$refs.mymap.mapObject
      map.setView(position, 18)
    }
  },
  sockets: {
    trackers (payload) {
      if (!empty(payload.lat) && payload.lat !== '0' && !empty(payload.long) && payload.long !== '0') {
        this.markers = this.markers.filter(marker => marker.id !== payload.uuid)
        this.markers.push({
          position: { lat: payload.lat, lng: payload.long },
          name: payload.name,
          status: payload.tipo_alerta,
          updated_at: payload.updated_at,
          deveui: payload.deveui,
          lat: payload.lat,
          long: payload.long,
          battery: batteryCalculate(payload.battery),
          id: payload.uuid,
          draggable: false,
          visible: true
        })
        if (this.fitBounds) {
          this.setFitBounds()
        }

        if (payload.tipo_alerta !== 'S') {
          this.alertTrackers = this.alertTrackers.filter(marker => marker.id !== payload.uuid)
          this.onAlert = true
          this.alertTrackers.push({
            id: payload.uuid,
            name: payload.name,
            status: payload.tipo_alerta,
            position: [payload.lat, payload.long]
          })
        }
      }
    }
  },
  created () {
    this.getMapa()
    this.getOptionsTrackers()
  },
  watch: {
    checkAll (val) {
      if (val) {
        this.filter = []
      }
    },
    filter (val) {
      if (val.length > 0) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  @import "@/assets/scss/pages/Map.scss";
</style>
